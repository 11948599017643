import { Loading } from '@hpx-core-experiences/react-my-account-commons/dist';
import React, { Suspense } from 'react';
import { memo } from 'react';
import { usePathname } from 'src/hooks/usePathname';
import PageView from './PageView';

const Fallback: React.FC = () => <Loading testid="loading-profile-mfe" />;

const RouteView = () => {
  const pageName = usePathname();

  return (
    <Suspense fallback={<Fallback />}>
      <PageView pageName={pageName} />
    </Suspense>
  );
};
export default memo(RouteView);
