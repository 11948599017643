export enum FeatureFlagEnum {
  homePage = 'home-page',
  profileHpIdCountryRegion = 'profile-hp-id-country-region',
  profileHpIdEmail = 'profile-hp-id-email',
  profileHpIdName = 'profile-hp-id-name',
  profileHpIdPhone = 'profile-hp-id-phone',
  profileHpIdPreferredLanguage = 'profile-hp-id-preferred-language',
  profileListPersonalDetails = 'profile-list-personal-details',
  qualtricsSurvey = 'qualtrics-survey',
  welcomeNonUsUsers = 'welcome-non-us-users',
  originalFeedbackExperience = 'original-feedback-experience'
}

export enum FormTypes {
  Country = 'country',
  Email = 'email',
  Language = 'language',
  Name = 'name',
  Phone = 'phone'
}
