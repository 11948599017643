import React, { memo, useMemo } from 'react';
import useFeatureFlagsState from 'src/hooks/useFeatureFlagsState';
import ROUTES from '../routes';

const PageView = ({ pageName }: { pageName: string }) => {
  const { flags, flagsLoadingState } = useFeatureFlagsState();
  let pageNameFormated = pageName;

  if (!Object.keys(ROUTES).includes(pageName)) {
    pageNameFormated = 'profile';
  }

  const PageComponent = useMemo(
    () => ROUTES[pageNameFormated],
    [pageNameFormated]
  );

  return (
    <>
      {PageComponent && (
        <PageComponent
          flags={flags}
          flagsLoadingState={flagsLoadingState}
        />
      )}
    </>
  );
};

export default memo(PageView);
