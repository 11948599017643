export const launchDarklyClientKey = 'myaccount';

export const featureFlagsDefaultValues = {
  welcomeNonUsUsers: true,
  profileHpIdName: false,
  qualtricsSurvey: false,
  profileListPersonalDetails: false,
  homePage: false,
  originalFeedbackExperience: false
} as any;
