import { lazy, LazyExoticComponent } from 'react';
import { FeatureFlagProps } from 'src/types/FeatureFlag';

type MemoizedPagesType = LazyExoticComponent<
  ({ flags, flagsLoadingState }: FeatureFlagProps) => JSX.Element
>;

type RoutesType = {
  [key: string]: MemoizedPagesType;
};

const ROUTES: RoutesType = {
  profile: lazy(
    () => import('../pages/Profile')
  ) as unknown as MemoizedPagesType,
  name: lazy(
    () => import('../pages/PersonalDetailsEditor')
  ) as unknown as MemoizedPagesType,
  email: lazy(
    () => import('../pages/PersonalDetailsEditor')
  ) as unknown as MemoizedPagesType,
  country: lazy(
    () => import('../pages/PersonalDetailsEditor')
  ) as unknown as MemoizedPagesType,
  phone: lazy(
    () => import('../pages/PersonalDetailsEditor')
  ) as unknown as MemoizedPagesType,
  language: lazy(
    () => import('../pages/PersonalDetailsEditor')
  ) as unknown as MemoizedPagesType
};

export default ROUTES;
